import React from "react";
import styled from "styled-components";

import TopNavMenu from "./TopNavMenu";

import framedBear from "./media/framed-prints/Framed Bear Mandala Website.jpg";
import framedBullsEye from "./media/framed-prints/Framed Bulls Eye Mandala Website.jpg";
import framedFlowerSketch from "./media/framed-prints/Framed Flower Sketch Website.jpg";
import framedMessengers from "./media/framed-prints/Framed Messengers Mandala Website.jpg";
import framedMosaicMandala from "./media/framed-prints/Framed Mosaic Mandala Website.jpg";
import framedSacredPentagram from "./media/framed-prints/Framed Sacred Pentagram Blackhole Mandala Website.jpg";

const MainAppDiv = styled.div`
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 0 15%;
  height: 100%;
  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const FramedPrintsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  margin: 1rem 0;
  width: 100%;
  @media only screen and (max-width: 400px) {
    img {
      width: 275px;
      height: 375px;
    }
  }
`;

const FramedPrint = styled.a`
  display: flex;
  color: black;
  flex-direction: column;
  align-items: flex-start;
  img {
    margin: 0 0 5px 0;
    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
  @media only screen and (max-width: 400px) {
    align-items: center;
  }
  font-size: 1rem;
  text-decoration: none;
`;

const Title = styled.span`
  font-size: 12px;
  margin: 5px 0 3px 0;
  max-width: 150px;
  @media only screen and (max-width: 400px) {
    text-align: center;
  }
`;

function App() {
  return (
    <MainAppDiv>
      <TopNavMenu />
      <FramedPrintsDiv>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/DARS4EYNFJDV65EAOOECA2JO"
          target="_blank"
        >
          <img
            alt="Framed Flower Sketch (Original)"
            src={framedFlowerSketch}
            height="200"
            width="150"
          />
          <Title>Framed Flower Sketch (Original)</Title>
          <span>100$</span>
        </FramedPrint>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/YMEYQ6NMBDRVR57MKKFESZA6"
          target="_blank"
        >
          <img
            alt="Framed Mosaic Mandala Print"
            src={framedMosaicMandala}
            height="200"
            width="150"
          />
          <Title>Framed Mosaic Mandala Print</Title>
          <span>35$</span>
        </FramedPrint>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/4S6OHONBQIDC3MD7MGGPSPY3"
          target="_blank"
        >
          <img
            alt="Framed Bear Mandala Print"
            src={framedBear}
            height="200"
            width="150"
          />
          <Title>Framed Bear Mandala Print</Title>
          <span>35$</span>
        </FramedPrint>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/6BGPX6SSNDTV5D2VHW3N7D6V"
          target="_blank"
        >
          <img
            alt="Framed Messengers Mandala Print"
            src={framedMessengers}
            height="200"
            width="150"
          />
          <Title>Framed Messengers Mandala Print</Title>
          <span>35$</span>
        </FramedPrint>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/MZ4VDIDIQJMFG4A4PQNLM2V5"
          target="_blank"
        >
          <img
            alt="Framed Sacred Pentagram Blackhole Print"
            src={framedSacredPentagram}
            height="200"
            width="150"
          />
          <Title>Framed Pentagram Blackhole Print</Title>
          <span>35$</span>
        </FramedPrint>
        <FramedPrint
          href="https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/BR25SKYQAV67UFNYDMDLZTJC"
          target="_blank"
        >
          <img
            alt="Framed Bull's Eye Mandala Print"
            src={framedBullsEye}
            height="200"
            width="150"
          />
          <Title>Framed Bull's Eye Mandala Print</Title>
          <span>35$</span>
        </FramedPrint>
      </FramedPrintsDiv>
    </MainAppDiv>
  );
}

export default App;
