import mountOlympusPrint from "./media/MountOlympusMandalaPrint.jpg";
import colorfulFlowerRoseMandalaPrint from "./media/ColorfulFlowerRoseMandalaPrint.jpg";
import daggertailBlackAndWhiteMandalaPrint from "./media/DaggertailBlackAndWhiteMandalaPrint.jpg";
import mosaicMandalaFlowerPrint from "./media/MosaicMandalaFlowerPrint.jpg";
import polenisianMandalaII from "./media/PolenisianMandalaII.jpg";
import tealAndOrangeDotMandalaOwlPrint from "./media/TealAndOrangeDotMandalaOwlPrint.jpg";
import venomHoneyMandalaPrint from "./media/VenomHoneyMandalaPrint.jpg";

import comfortZoneLargePrint from "./media/large-prints/comfort-zone-16x16-poster.jpg";
import floralSigilLargePrint from "./media/large-prints/floral-sigil-16x16-poster.jpg";
import redOchreFallLargePrint from "./media/large-prints/red-ochre-fall-mandala-16x16-poster.jpg";
import yellowTealRoyalMandalaLargePrint from "./media/large-prints/yellow-teal-royal-mandala-16x16-poster.jpg";

import cabbageFlowerMural from "./media/murals/cabbage-flower-mural-website.jpg";
import quadrantFlowerMural from "./media/murals/quadrant-flower-mural-website.jpg";
import roseMandalaMural from "./media/murals/rose-mandala-mural-website.jpg";
import tileMural from "./media/murals/tile-mural-website.jpg";
import trifectaFlowerMural from "./media/murals/trifecta-flower-mural-website.jpg";

const smallPrints = [
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/IROTYXOJXSCO6MZEZJM6253B',
        height: 200,
        width: 150,
        title: 'Mosaic Mandala',
        description: 'This mandala was primarily inspired by the mosaic tiling found in various holy sites around the world. It also features floral elements and a very light background to brighten up any indoor space!',
        type: 'Digital painting',
        price: 20,
        srcImg: mosaicMandalaFlowerPrint,
        imgAlt: 'Mosaic Mandala Print',
        id: 'mosaic-mandala-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/JSSTD5VVRJ7S3LCTHGZGAK2L',
        height: 200,
        width: 150,
        title: 'Venom Honey Mandala',
        description: 'A very detailed mandala which draws inspiration from venus fly traps. This beautiful piece will absorb your gaze and any bad juju headed your way!',
        type: 'Digital painting',
        price: 20,
        srcImg: venomHoneyMandalaPrint,
        imgAlt: 'Venom Honey Mandala Print',
        id: 'venom-honey-mandala-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/VGUHMZMSSYQRYUUZHLJRFZSB',
        height: 200,
        width: 150,
        title: 'Mount Olympus Mandala',
        type: 'Digital painting',
        description: 'This mandala celebrates Mount Olympus, the highest mountain in Greece, home of the Greek gods. The bright colors aim to celebrate the richness of its flora which span from the beaches of the Aegean Sea to the top of the mount itself.',
        price: 20,
        srcImg: mountOlympusPrint,
        imgAlt: 'Mount Olympus Mandala Print',
        id: 'mount-olympus-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/G65K5CNVKEMYOIYHUAUWNWYA',
        height: 200,
        width: 150,
        title: 'Polynesian Mandala II',
        description: 'The perfect fusion between traditional mandala elements and polynesian style patterns. The wave symbols and looping arrows represent the brevity and cyclical nature of life, a reminder to live and enjoy the present moment.',
        type: 'Digital painting',
        price: 20,
        srcImg: polenisianMandalaII,
        imgAlt: 'Polynesian Mandala II Print',
        id: 'polynesian-mandala-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/DBW2GXUK472G5G5CD4GX6QE3',
        height: 200,
        width: 150,
        title: 'Daggertail Mandala',
        description: 'A black and white geometric piece inspired by sacred geometry and the flow induced by whale tails as they move through water.',
        type: 'Digital painting',
        price: 20,
        srcImg: daggertailBlackAndWhiteMandalaPrint,
        imgAlt: 'Daggertail Mandala Print',
        id: 'daggertail-mandala-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/ARVYROGMXENZ2KM4LAMYY6UY',
        height: 200,
        width: 150,
        title: 'Teal & Orange Owl Mandala',
        description: 'A vivid owl guardian mandala to look after you.',
        type: 'Digital painting',
        price: 20,
        srcImg: tealAndOrangeDotMandalaOwlPrint,
        imgAlt: 'Teal And Orange Mandala Owl Print',
        id: 'teal-orange-owl-mandala-print',
    },
    {
        href: 'https://checkout.square.site/merchant/MLSCENJ1BV96J/checkout/FWTYDCPB2QBYQ3JHXPBZCBQI',
        height: 200,
        width: 150,
        title: 'Colorful Flower Rose Mandala',
        description: 'A nature inspired floral mandala with very vibrant colors. This beautiful piece will send waves of positive energy in your household.',
        type: 'Digital painting',
        price: 20,
        srcImg: colorfulFlowerRoseMandalaPrint,
        imgAlt: 'Colorful Flower Rose Mandala Print',
        id: 'colorful-flower-rose-print',
    },
];

const largePrints = [
    {
        href: 'https://square.link/u/8370zMBh',
        height: 200,
        width: 200,
        title: 'Comfort Zone Mandala',
        type: 'Digital painting',
        description: 'A bright and colorful digital mandala celebrating the diversity of nature and radiating waves of positive energy.',
        price: 35,
        srcImg: comfortZoneLargePrint,
        imgAlt: 'Comfort Zone Mandala Print',
        id: 'comfort-zone-print',
    },
    {
        href: 'https://square.link/u/YOnUAHLY',
        height: 200,
        width: 200,
        title: 'Floral Sigil Mandala',
        type: 'Digital painting',
        description: 'Inspired by own mural work, this satisfying symmetrical piece is a unique take on chrysanthemum flowers. This sigil protects its owners from bad juju.',
        price: 35,
        srcImg: floralSigilLargePrint,
        imgAlt: 'Floral Sigil Mandala Print',
        id: 'floral-sigil-print',
    },
    {
        href: 'https://square.link/u/2HJEiixS',
        height: 200,
        width: 200,
        title: 'Red Ochre Autumn Totem Mandala',
        type: 'Digital painting',
        description: 'This intense red/ochre Mandala is representative of the shifting seasons, falling leaves and trees preparing for the colder season. A sacred totem emblematic of the fall season.',
        price: 35,
        srcImg: redOchreFallLargePrint,
        imgAlt: 'Red Ochre Fall Totem Mandala Print',
        id: 'red-ochre-totem-print',
    },
    {
        href: 'https://square.link/u/rqCcPqYw',
        height: 200,
        width: 200,
        title: 'Royal Sun Crest Mandala',
        type: 'Digital painting',
        description: 'Yellow, Teal & Purple Royal sun crest Mandala.',
        price: 35,
        srcImg: yellowTealRoyalMandalaLargePrint,
        imgAlt: 'Royal Sun Crest Mandala Print',
        id: 'royal-sun-crest-print',
    },
];

const murals = [
    {
        height: 375,
        width: 375,
        title: 'Rose Mandala Mural',
        srcImg: roseMandalaMural,
        imgAlt: 'Rose Mandala Mural',
    },
    {
        height: 375,
        width: 375,
        title: 'Cabbage Flower Mural',
        srcImg: cabbageFlowerMural,
        imgAlt: 'Cabbage Flower Mural',
    },
    {
        height: 375,
        width: 375,
        title: 'Quadrant Flower Mural',
        srcImg: quadrantFlowerMural,
        imgAlt: 'Quadrant Flower Mural',
    },
    {
        height: 375,
        width: 375,
        title: 'Tile Mural',
        srcImg: tileMural,
        imgAlt: 'Tile Mural',
    },
    {
        height: 375,
        width: 375,
        title: 'Trifecta Flower Mural',
        srcImg: trifectaFlowerMural,
        imgAlt: 'Trifecta Flower Mural',
    },
];

export {
  smallPrints,
  largePrints,
  murals,
};

