import React from "react";
import styled from "styled-components";

import TopNavMenu from "./TopNavMenu";
import Murals from './Murals';

const MainAppDiv = styled.div`
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 0 15%;
  height: 100%;
  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

function WrappedMurals() {
  return (
    <MainAppDiv>
      <TopNavMenu />
      <Murals />
    </MainAppDiv>
  );
}

export default WrappedMurals;
