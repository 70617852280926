import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import PropTypes from 'prop-types';

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const UnderlinedStyledLink = styled(StyledLink)`
  text-underline-offset: 3px;
  text-decoration: underline;
`;

function LinkMenuItem({ path, title }) {
  const { pathname } = useLocation();
  const isSelected = pathname === path;
  const MenuComponent = isSelected ? UnderlinedStyledLink : StyledLink;
  return (
    <MenuComponent to={path}>{title}</MenuComponent>
  );
}

LinkMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LinkMenuItem;