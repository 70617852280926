import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import WrappedSmallPrints from "./WrappedSmallPrints";
import WrappedLargePrints from "./WrappedLargePrints";
import WrappedMurals from "./WrappedMurals";
import About from "./About";
import FramedPrints from "./FramedPrints";
import Print from './Print';

const StyledApp = createGlobalStyle`
  body {
    background-color: white;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route exact path="/murals" element={
            <React.Fragment>
              <StyledApp />
              <WrappedMurals />
            </React.Fragment>
          } />
          <Route exact path="/small-prints" element={
            <React.Fragment>
              <StyledApp />
              <WrappedSmallPrints />
            </React.Fragment>
          } />
          <Route exact path="/large-prints" element={
            <React.Fragment>
              <StyledApp />
              <WrappedLargePrints />
            </React.Fragment>
          } />
          <Route path="/prints/:printId" element={
            <React.Fragment>
              <StyledApp />
              <Print />
            </React.Fragment>
          } />
          <Route path="/framed-prints" element={
            <React.Fragment>
              <StyledApp />
              <FramedPrints />
            </React.Fragment>
          } />
          <Route path="/about" element={
            <React.Fragment>
              <StyledApp />
              <About />
            </React.Fragment>
          } />
          <Route path="*" element={<Navigate to="/murals" replace />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
