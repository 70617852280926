import React from "react";
import styled from "styled-components";

import TopNavMenu from "./TopNavMenu";

import artOfSchwarzProfilePic from "./media/artofschwarzprofilepic.jpg";

const MainAppDiv = styled.div`
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;
  height: 100%;
  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const AboutDiv = styled.div`
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  width: 100%;
  @media only screen and (max-width: 768px) {
    .square {
      height: 400px;
      width: 400px;
    }
    .rect {
      height: 300px;
      width: 400px;
    }
  }
  @media only screen and (max-width: 500px) {
    .square {
      height: 300px;
      width: 300px;
    }
    .rect {
      height: 225px;
      width: 300px;
    }
  }
  @media only screen and (max-width: 400px) {
    .square {
      height: 300px;
      width: 300px;
    }
    .rect {
      height: 200px;
      width: 250px;
    }
  }
`;

const SpacedImage = styled.img`
  margin: 1rem 0;
`;

const StyledExternalLink = styled.a`
  color: black;
`;

const SpacedText = styled.span`
  margin: 1rem 0;
`;

function App() {
  return (
    <MainAppDiv>
      <TopNavMenu />
      <AboutDiv>
        <h1>Hello there!</h1>
        I’m Chrys - A Montreal based visual artist with a passion for mandalas
        and patterns. I inspire from a variety of cultures and folklore to
        create unique pieces which often symbolize our need for
        interconnectedness and sharing.
        <SpacedImage
          className="square"
          alt="Schwarz"
          src={artOfSchwarzProfilePic}
          width="713"
          height="713"
        />
        <SpacedText>
          I like to experiment with a variety of mediums but specialize in
          crafting intricate art pieces with ink, colored pencils, markers as
          well as digitally drawn pieces and most recently acrylic and spray
          painting.
        </SpacedText>
        <SpacedText>
          Do you have an idea for a mural ? or perhaps you're looking for a
          custom commission ? Reach out to me on Instagram{" "}
          <StyledExternalLink
            target="_blank"
            href="https://www.instagram.com/artofschwarz/"
          >
            @artofschwarz
          </StyledExternalLink>
          , I'll be happy to discuss it.
        </SpacedText>
      </AboutDiv>
    </MainAppDiv>
  );
}

export default App;
