import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { largePrints } from "./data";

const PrintsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  margin: 1rem 0;
  width: 100%;
  @media only screen and (max-width: 400px) {
    img {
      width: 275px;
      height: 375px;
    }
    .squared {
      width: 300px;
      height: 300px;
    }
  }
`;

const Print = styled(Link)`
  display: flex;
  color: black;
  flex-direction: column;
  align-items: flex-start;
  img {
    margin: 0 0 5px 0;
    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
  @media only screen and (max-width: 400px) {
    align-items: center;
  }
  font-size: 1rem;
  text-decoration: none;
`;

const Title = styled.span`
  font-size: 12px;
  margin: 5px 0 3px 0;
  max-width: 150px;
  @media only screen and (max-width: 400px) {
    text-align: center;
  }
`;

function LargePrint({ id, height, width, title, price, srcImg, imgAlt }) {
  const dynamicClass = height === width ? { className: "squared" } : {};
  return (
    <Print to={`/prints/${id}`}>
      <img
        alt={imgAlt}
        src={srcImg}
        height={height}
        width={width}
        {...dynamicClass}
      />
      <Title>{title}</Title>
      <span>{price}$</span>
    </Print>
  );
}

LargePrint.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  srcImg: PropTypes.node.isRequired,
  imgAlt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function LargePrints() {
  return (
    <PrintsDiv>
      {largePrints.map((printProperties) => (
        <LargePrint key={printProperties.id} {...printProperties} />
      ))}
    </PrintsDiv>
  );
}

export default LargePrints;
