import { Fragment } from "react";
import styled from "styled-components";

import LinkMenuItem from './LinkMenuItem';

import artOfSchwarzBanner from "./media/artofschwarzbanner.jpg";
import IGLogo from "./media/Instagram_Glyph_Black.svg";

const BannerDiv = styled.div`
  display: inline-flex;
  justify-content: center;
  padding: 5px;
  @media only screen and (min-width: 769px) {
    img {
      width: 500px;
    }
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 400px;
    }
  }
  @media only screen and (max-width: 500px) {
    img {
      width: 300px;
    }
  }
  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
    img {
      width: 280px;
    }
  }
`;

const StyledNav = styled.nav`
  color: black;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1rem 0;
`;

const ExternalLink = styled.a`
  margin: 5px 0 0 0;
`;

export default function TopNavMenu() {
  return (
    <Fragment>
      <BannerDiv>
        <img alt="artofschwarz banner" src={artOfSchwarzBanner} />
      </BannerDiv>
      <StyledNav>
        <LinkMenuItem path="/murals" title="Murals" />
        <LinkMenuItem path="/framed-prints" title="Framed Prints" />
        <LinkMenuItem path="/large-prints" title="16x16 Prints" />
        <LinkMenuItem path="/small-prints" title="8x11 Prints" />
        <LinkMenuItem path="/about" title="About" />
        <ExternalLink
          target="_blank"
          href="https://www.instagram.com/artofschwarz/"
        >
          <img
            alt="To artofschwarz Instagram"
            src={IGLogo}
            height="15"
            width="15"
          />
        </ExternalLink>
      </StyledNav>
    </Fragment>
  );
}
