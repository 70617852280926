import React from "react";
import { useParams } from 'react-router-dom';
import styled from "styled-components";

import TopNavMenu from "./TopNavMenu";

import { smallPrints, largePrints } from './data';

const MainAppDiv = styled.div`
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 0 15%;
  height: 100%;
  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const PrintDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

const ImageDiv = styled.div`
`;

const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 1rem;
  height: 375px;
`;

const StyledSubtitleSpan = styled.span`
  color: gray;
  font-size: 12px;
  font-style: italic;
  margin: 0 5px;
`;

const StyledDescSpan = styled.span`
  margin: 0 0 1rem 0;
`;

const StyledParagrath = styled.p`
  max-width: 280px;
  text-underline: none;
`;

const StyledLink = styled.a`
  border: 1px black solid;
  border-radius: 4px;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  :hover {
    color: white;
    background-color: black;
  }
`;

function Print() {
  const { printId } = useParams();
  const allPrints = [...smallPrints, ...largePrints];
  const { href, title, price, srcImg, imgAlt, description, type, height, width } = allPrints.find(({ id }) => id === printId);
  const isSquare = height === width;
  return (
      <MainAppDiv>
        <TopNavMenu />
        <PrintDiv>
          <ImageDiv>
            <img alt={imgAlt} src={srcImg} height="400" width={isSquare ? 400 : 300} />
          </ImageDiv>
          <DescriptionDiv>
            <h2>{title}</h2>
            {type && <StyledSubtitleSpan>{type}</StyledSubtitleSpan>}
            {description && <StyledParagrath>{description}</StyledParagrath>}
            <StyledDescSpan>{price}$</StyledDescSpan>
            <StyledLink href={href} target="_blank" rel="noreferrer">Order this print</StyledLink>
          </DescriptionDiv>
        </PrintDiv>
      </MainAppDiv>
  );
}

Print.propTypes = {
}

export default Print;
