import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { murals } from "./data";

const MuralsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0;
  width: 100%;
  @media only screen and (max-width: 400px) {
    img {
      width: 300px;
      height: 300px;
    }
  }
`;

const MuralDiv = styled.div`
  display: flex;
  color: black;
  flex-direction: column;
  align-items: flex-start;
  img {
    margin: 0 0 5px 0;
    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
  @media only screen and (max-width: 400px) {
    align-items: center;
  }
  @media only screen and (min-width: 1080px) {
    img {
      width: 600px;
      height: 600px;
    }
  }
  font-size: 1rem;
  text-decoration: none;
`;

const Title = styled.span`
  font-size: 12px;
  margin: 5px 0 3px 0;
  max-width: 150px;
  @media only screen and (max-width: 400px) {
    text-align: center;
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
`;

function Mural({ height, width, title, srcImg, imgAlt }) {
  return (
    <MuralDiv>
      <img alt={imgAlt} src={srcImg} height={height} width={width} />
      <Title>{title}</Title>
    </MuralDiv>
  );
}

Mural.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  srcImg: PropTypes.node.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

function Murals() {
  return (
    <MuralsDiv>
      <StyledParagraph>
        Hello there ! welcome to art of schwarz !
        <br />
        I've recently started doing mural work with spray cans and have been
        having an absolute blast !
        <br />
        Here are some of my latest murals from 2023, enjoy.
      </StyledParagraph>
      {murals.map((printProperties) => (
        <Mural key={printProperties.title} {...printProperties} />
      ))}
    </MuralsDiv>
  );
}

export default Murals;
